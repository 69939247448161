import React from 'react';

import { JobApplyForm } from '../../components/job-apply-form-modal';
import SEO from '../../components/seo';
import styles from './job.module.css';

const RegionalDirectorCz = () => {
  return (
    <>
      <SEO title="Regionální ředitel pro ČR" />
      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Regionální ředitel pro ČR</h1>
          <p className={styles.text}>NutritionPro je jedním z nejrychleji rostoucích Foodtech startupů v ČR (500 %+ meziroční růst).</p>
          <p className={styles.text}>
          Vyrábíme stovky tisíc jídel měsíčně a jsme největší společnost v České republice, která poskytuje personalizované jídelníčky s kompletním servisem.
          </p>
          <p className={styles.text}>
          Jestli chceš být součástí nadšeného týmu a rozšířit supercrew úspěšného startupu v oblasti FoodTech, tak čti dál! 
          </p>

          <h3 className={styles.textSubHeader}>Co u nás budeš dělat?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
            řídit celou společnost NutritionPro v rámci ČR
            </li>
            <li className={styles.newList}>
            zodpovídat za správu a dosahování zadaných KPI cílů pro ČR
            </li>
            <li className={styles.newList}>
            podporovat firemní vizí, řídit, koordinovat a kontrolovat provoz
            </li>
            <li className={styles.newList}>
            podporovat zaměstnance, aby dosahovali maximálních výkonů (KPI, OKR)
            </li>
            <li className={styles.newList}>
            zodpovídat za rozvojové aktivity v ČR (investice, akvizice atd.)
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co od tebe očekáváme? </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
            jsi energeticky, ambiciózní a charismatický lídr
            </li>
            <li className={styles.newList}>
            máš prokazatelnou předchozí praxi s vedením týmu
            </li>
            <li className={styles.newList}>
            jsi data-driven achiever a čísla jsou pro tebe hlavním měřítkem – dokážeš se profesionálně řídit podle metrik, analyzovat je a dle výsledků přizpůsobovat celkovou strategii
            </li>
            <li className={styles.newList}>jsi otevřený novým oblastem, umíš se rychle adaptovat na změnu a jsi flexibilní</li>
            <li className={styles.newList}>dovedeš vést lidi a motivovat je, jsi týmový hráč, umíš delegovat práci a vyhodnocovat výsledky</li>
            <li className={styles.newList}>
            umíš velmi dobře komunikovat a dotahuješ věcí do konce
            </li>
          </ul>
          <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>budeš mít klíčovou roli v rozvojí produktu, který má vliv na zdraví desítek tisíc lidí</li>
            <li className={styles.newList}>možnost realizace vlastních nápadů a mít rozhodující vliv na chod celé společnosti</li>
            <li className={styles.newList}>
            spolupráce s ambiciózním tymem nadšenců
            </li>
            <li className={styles.newList}>
            nárok na zaměstnanecké akcie (ESOP)
            </li>
            <li className={styles.newList}>
            motivační finanční ohodnocení a měsíční bonus
            </li>
            <li className={styles.newList}>
            plně hrazené vzdělávací kurzy a konzultace s předními odborníky v oboru
            </li>
            <li className={styles.newList}>
            firemní obědy zdarma a multisport kartu
            </li>
            <li className={styles.newList}>
            moderní kanceláře v srdci Karlína
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>
          Těšíme se na tvoje CV a rádi tě uvítáme v našem týmu NutritionPro!
          </h3>
          <h3 className={styles.textSubHeader}>Kontakty:</h3>
          <a className={styles.jobsEmail} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_eventManager" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default RegionalDirectorCz
